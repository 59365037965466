import Vue from 'vue';
import VueI18n from 'vue-i18n';
import fr from '@/vue-i18n/locales/fr';
import en from '@/vue-i18n/locales/en';

Vue.use(VueI18n);

const messages = { en, fr };

const i18n = new VueI18n({
  locale: localStorage.getItem('locale') == null ? 'fr' : localStorage.getItem('locale'), // Anglais par défault
  fallbackLocale: 'fr',
  messages,
});


export default i18n;
