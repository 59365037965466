<template>
    <div ref="chart">
    </div>
</template>

<script>
    // https://www.npmjs.com/package/apexcharts
    import ApexCharts from 'apexcharts'
    import DateUtils from "../../utils/DateUtils";

    export default {
        name: 'ChartSimulationNormal',
        props: {
            data: Object,
        },
        data() {
            return {
                options: null,
                series: [],
                categories: [],
            };
        },
        methods: {
            loadGraph() {
                let serieToDisplay = {
                    name: this.$t('simulation.normal.chart.yaxis'),
                    data: [],
                };
                this.data.bankrollHistory.forEach((bankroll, index) => {
                    let dataObject = {
                        name: this.data.nameHistory[index],
                        sportName: this.data.sportHistory[index],
                        x: DateUtils.toTime(this.data.timeHistory[index]),
                        y: bankroll.toFixed(2),
                    };
                    serieToDisplay.data.push(dataObject);
                });
                this.series.push(serieToDisplay);

                this.options = {
                    chart: {
                        type: 'line',
                        height: 400,
                        dropShadow: {
                            enabled: true,
                            color: '#000',
                            top: 18,
                            left: 7,
                            blur: 10,
                            opacity: 0.2,
                        },
                        toolbar: {
                            show: !this.isMobile(),
                        },
                        zoom: {
                            type: 'x',
                            enabled: true,
                            autoScaleYaxis: true
                        },
                    },
                    series: this.series,
                    xaxis: {
                        type: 'datetime',
                        title: {
                            text: this.$t("simulation.normal.chart.xaxis")
                        },
                        labels: {
                            datetimeUTC: false
                        }
                    },
                    yaxis: {
                        title: {
                            text: this.$t('simulation.normal.chart.yaxis')
                        },
                    },
                    tooltip: {
                        custom: function (opts) {
                            let name = opts.ctx.w.config.series[opts.seriesIndex].data[opts.dataPointIndex].name;
                            let sportName = opts.ctx.w.config.series[opts.seriesIndex].data[opts.dataPointIndex].sportName;
                            let time = DateUtils.formatHumanDateTime(opts.ctx.w.config.series[opts.seriesIndex].data[opts.dataPointIndex].x);
                            let gain = opts.ctx.w.config.series[opts.seriesIndex].data[opts.dataPointIndex].y;
                            return '<div></div><div class="apexcharts-tooltip-title" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px; display: flex;"><strong>' + sportName + '</strong> : ' + name + '</div>' +
                            '<div class="apexcharts-tooltip-series-group apexcharts-active" style="display: flex;">' +
                            '<span class="apexcharts-tooltip-marker" style="background-color: rgb(0, 143, 251);"></span>' +
                            '<div class="apexcharts-tooltip-text" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">' +
                            '<div class="apexcharts-tooltip-y-group"><span class="apexcharts-tooltip-text-label">' + gain + '€ : </span>' +
                            '<span class="apexcharts-tooltip-text-value">' + time + '</span>' +
                            '</div><div class="apexcharts-tooltip-z-group"><span class="apexcharts-tooltip-text-z-label"></span>' +
                            '<span class="apexcharts-tooltip-text-z-value"></span></div></div>' +
                            '</div></div>'
                        }
                    },
                    title: {
                        text: this.$t('simulation.normal.chart.title'),
                        align: 'left'
                    },
                    stroke: {
                        curve: 'smooth'
                    },
                    grid: {
                        borderColor: '#e7e7e7',
                        row: {
                            colors: ['#f3f3f3', 'transparent'],
                            opacity: 0.5
                        },
                    },
                    animations: {
                        enabled: false,
                    },
                    markers: {
                        size: 0,
                    },
                };
                let chart = new ApexCharts(this.$refs.chart, this.options);
                chart.render();
            },
            isMobile() {
                return screen.width <= 760;
            }
        },
        mounted() {
            this.loadGraph();
        },
    }
</script>
