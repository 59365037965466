<template>
    <form novalidate>
        <slot>No form setted</slot>
    </form>
</template>

<script>
    import $ from 'jquery'

    export default {
        name: 'FormValidation',
        mounted() {
            const forms = $('form');
            Array.prototype.filter.call(forms, (form) => {
                form.addEventListener('submit', (event) => {
                    if (form.checkValidity() === true) {
                        this.$emit('submit');
                    }
                    event.preventDefault();
                    event.stopPropagation();
                    form.classList.add('was-validated');
                }, false);
            });
        },
    };
</script>
