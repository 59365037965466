import Vue from 'vue';
import Router from 'vue-router';
// import i18n from '@/vue-i18n/i18n';
// import AlertBus from '@/event-bus/AlertBus';
// import UserService from '@/services/UserService';

import PageLayout from '@/containers/PageLayout';
import Home from '@/components/Home';
import Sport from '@/components/Sport';
import SportEvent from '@/components/SportEvent';
import Match from '@/components/Match';
import Simulation from '@/components/Simulation';
import SimulationNormal from '@/components/SimulationNormal';
import SimulationCategory from '@/components/SimulationCategory';
import CategoryDetails from '@/components/CategoryDetails';
import CategoryBest from '@/components/CategoryBest';

Vue.use(Router);

const router = new Router({
    mode: 'history',
    routes: [
        {
            path: '',
            component: PageLayout,
            children: [
                {
                    path: '',
                    component: Home,
                    name: 'home',
                    meta: {
                        title: 'Home',
                        metaTags: [
                            {
                                name: 'description',
                                content: 'Home page'
                            },
                        ]
                    },
                },
                {
                    path: '/sport/:id',
                    component: Sport,
                    name: 'sport',
                    meta: {
                        requiresAuth: true,
                        title: 'Sport',
                        metaTags: [
                            {
                                name: 'description',
                                content: 'Sport page will display all match of the sport selected'
                            },
                        ]
                    },
                },
                {
                    path: '/sport/:id/event/:eventId',
                    component: SportEvent,
                    name: 'sport_event',
                    meta: {
                        requiresAuth: true,
                        title: 'SportEvent',
                        metaTags: [
                            {
                                name: 'description',
                                content: 'Sport page will display all match of the event sport selected'
                            },
                        ]
                    },
                },
                {
                    path: '/match/:id',
                    component: Match,
                    name: 'match',
                    meta: {
                        requiresAuth: true,
                        title: 'Match',
                        metaTags: [
                            {
                                name: 'description',
                                content: 'Match page will display all bet of the match selected'
                            },
                        ]
                    },
                },
                {
                    path: '/simulation/sport/:name/:id',
                    component: Simulation,
                    name: 'simulation',
                    meta: {
                        requiresAuth: true,
                        title: 'Simulation',
                        metaTags: [
                            {
                                name: 'description',
                                content: 'Simulation'
                            },
                        ]
                    },
                },
                {
                    path: '/simulation',
                    component: SimulationNormal,
                    name: 'simulatio_normal',
                    meta: {
                        requiresAuth: true,
                        title: 'Simulation Normal',
                        metaTags: [
                            {
                                name: 'description',
                                content: 'Simulation Normal'
                            },
                        ]
                    },
                },
                {
                    path: '/simulation/category',
                    component: SimulationCategory,
                    name: 'simulation_category',
                    meta: {
                        requiresAuth: true,
                        title: 'Simulation des Categories',
                        metaTags: [
                            {
                                name: 'description',
                                content: 'Simulation Category'
                            },
                        ]
                    },
                },
                {
                    path: '/simulation/category/:id/similar',
                    component: CategoryDetails,
                    name: 'category_details',
                    meta: {
                        requiresAuth: true,
                        title: 'Détails de la catégorie',
                        metaTags: [
                            {
                                name: 'description',
                                content: 'Category détails'
                            },
                        ]
                    },
                },
                {
                    path: '/simulation/category/best',
                    component: CategoryBest,
                    name: 'category_best',
                    meta: {
                        requiresAuth: true,
                        title: 'Simulation sur les meilleurs catégories',
                        metaTags: [
                            {
                                name: 'description',
                                content: 'Category Best'
                            },
                        ]
                    },
                },
            ],
        },
    ],
});


// router.beforeEach((to, from, next) => {
//     if (to.matched.some(record => record.meta.requiresAuth)
//         && !UserService.isLogged()) {
//         AlertBus.bus.$emit(AlertBus.ERROR, i18n.t('error.requiresAuth'));
//         AlertBus.bus.$emit('askLogin');
//     } else {
//         next();
//     }
// });

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
    // This goes through the matched routes from last to first, finding the closest route with a title.
    // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
    const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

    // Find the nearest route element with meta tags.
    const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

    // If a route with a title was found, set the document (page) title to that value.
    if(nearestWithTitle) document.title = nearestWithTitle.meta.title;

    // Remove any stale meta tags from the document using the key attribute we set below.
    Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

    // Skip rendering meta tags if there are none.
    if(!nearestWithMeta) return next();

    // Turn the meta tag definitions into actual elements in the head.
    nearestWithMeta.meta.metaTags.map(tagDef => {
        const tag = document.createElement('meta');

        Object.keys(tagDef).forEach(key => {
            tag.setAttribute(key, tagDef[key]);
        });

        // We use this to track which meta tags we create, so we don't interfere with other ones.
        tag.setAttribute('data-vue-router-controlled', '');

        return tag;
    })
    // Add the meta tags to the document head.
        .forEach(tag => document.head.appendChild(tag));

    next();
});

export default router;
