<template>
    <div>
        <ScaleLoader color="#04224c"/>
    </div>
</template>

<script>
    // https://github.com/greyby/vue-spinner
    import ScaleLoader from 'vue-spinner/src/ScaleLoader.vue'

    export default {
        name: 'Loader',
        components: {ScaleLoader},
    }
</script>
