<template>
  <div>
    <div class="row mt-5">
      <div class="col-12 mb-4 bg-blue p-4 border rounded">
        <h1 class="text-center text-white font-title">{{ $t('simulation.category.title') }}</h1>
      </div>
      <div class="col-12 mt-md-0 mt-3">
        <div v-if="data != null">
          <div class="mt-4 mb-5">
            <ChartSimulationCategory
                class="col"
                :data="data"/>
          </div>
        </div>
        <div class="col-12 mt-5 pt-5" v-else>
          <Loader v-if="loadingData"/>
        </div>
      </div>
      <div class="col-12 mt-md-0 mt-3">
        <div v-if="categories != null && categories.length > 0" class="table-responsive">
          <table class="table table-striped">
            <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Id</th>
              <th scope="col">{{ $t('simulation.category.details.sport') }}</th>
              <th scope="col">{{ $t('simulation.category.details.odd') }}</th>
              <th scope="col">{{ $t('simulation.category.details.variation') }}</th>
              <th scope="col">{{ $t('simulation.category.details.balance') }}</th>
              <th scope="col">{{ $t('simulation.category.details.vote') }}</th>
              <th scope="col">{{ $t('simulation.category.details.action.text') }}</th>
              <th scope="col">{{ $t('simulation.category.details.gain') }}</th>
              <th scope="col">{{ $t('simulation.category.details.count') }}</th>
              <th scope="col">{{ $t('simulation.category.details.gainPercent') }}</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(category, index) in categories" :key="category.id">
              <th scope="row">
                <a :href="'/simulation/category/' + category.betCategory.id + '/similar'" target="_blank">
                  {{ index + 1 }}
                </a>
              </th>
              <td>{{ category.betCategory.id }}</td>
              <td>{{ category.sportName }}</td>
              <td>{{ displayValue(category.betCategory.minOdd, category.betCategory.maxOdd) }}</td>
              <td>{{ displayValue(category.betCategory.minVariation * 100, category.betCategory.maxVariation * 100) }} (en %)</td>
              <td>{{ displayValue(category.betCategory.minBalance, category.betCategory.maxBalance, true) }} (en %)</td>
              <td>{{ displayValue(category.betCategory.minNbVote, category.betCategory.maxNbVote) }}</td>
              <td>
                <ToggleButton v-model="category.betCategory.betable"
                              @change="toggleCategory(category.betCategory.id, category.betCategory.betable)"
                              :width="80"
                              :sync="true"
                              :labels="{checked: $t('simulation.category.details.betable'),
                              unchecked: $t('simulation.category.details.notBetable')}">
                </ToggleButton>
              </td>
              <td>{{ category.gain}}€</td>
              <td>{{ category.count}}</td>
              <td>{{ category.gainPercent}}%</td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="col-12 mt-5 pt-5" v-else>
          <Loader v-if="loadingCategories"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ChartSimulationCategory from '@/components/common/ChartSimulationCategory';
import AlertBus from '@/event-bus/AlertBus';
import Loader from '@/components/common/Loader';

export default {
  name: 'SimulationCategory',
  components: {Loader, ChartSimulationCategory},
  data() {
    return {
      data: null,
      categories: null,
      loadingData: false,
      loadingCategories: false
    };
  },
  created() {
    this.simulate();
  },
  methods: {
    toggleCategory(categoryId, betable) {
      let url = `/api/category/${categoryId}/betable/${betable}`;
      this.$http.patch(url)
          .then((res) => {
            if (res.status === 200) {
              AlertBus.bus.$emit(AlertBus.SUCCESS, this.$t('simulation.category.details.action.success'));
            } else {
              AlertBus.bus.$emit(AlertBus.SUCCESS, res);
            }
          })
          .catch((response) => {
            AlertBus.bus.$emit(AlertBus.ERROR, response);
          });
    },
    displayValue(minValue, maxValue, isBalance = false) {
      let stringToDisplay = "";
      if (minValue == null) {
        stringToDisplay += "- de ";
      } else {
        if (isBalance) {
          minValue = (minValue - 1) * 100;
        }
        stringToDisplay += minValue.toFixed(2).replace(/[.,]00$/, "");
      }
      if(minValue != null && maxValue != null) {
        stringToDisplay += " - ";
      }
      if (maxValue == null) {
        stringToDisplay += " et +";
      } else {
        if (isBalance) {
          maxValue = (maxValue - 1) * 100;
        }
        stringToDisplay += maxValue.toFixed(2).replace(/[.,]00$/, "");
      }
      return stringToDisplay;
    },
    simulate() {
      this.loadingData = true;
      this.loadingCategories = true;
      this.data = null;
      this.categories = null;

      let url = `/api/category/best/data`;
      this.$http.get(url)
          .then((res) => {
            this.data = res.data;
            this.loadingData = false;
          })
          .catch((response) => {
            AlertBus.bus.$emit(AlertBus.ERROR, response);
          });

      let urlCategories = `/api/category/best`;
      this.$http.get(urlCategories)
          .then((res) => {
            this.categories = res.data;
            this.loadingCategories = false;
          })
          .catch((response) => {
            AlertBus.bus.$emit(AlertBus.ERROR, response);
          });
    },
  },
}
</script>
