<template>
  <div>
    <div class="row mt-5">
      <div class="col-12 mb-4 bg-blue p-4 border rounded">
        <h1 class="text-center text-white font-title">{{ $t('sport.search') }}</h1>
        <h2 v-if="matchs && matchs.length > 0" class="text-center text-white font-title">{{ matchs[0].event.name }}</h2>
      </div>
      <div class="col-12 mb-4 mt-md-0 mt-3 d-flex justify-content-between pl-5 pr-5">
        <button class="btn btn-blue font-weight-bold p-3 col-5" :class="pastView ? '' : 'desactivate'" v-on:click="clickButton(true)">
          {{ $t('sport.pastMatchs') }}
        </button>
        <button class="btn btn-blue font-weight-bold p-3 col-5" :class="pastView ? 'desactivate' : ''" v-on:click="clickButton(false)">
          {{ $t('sport.futureMatchs') }}
        </button>
      </div>
      <div class="col-12 col-md mt-md-0 mt-3" v-if="matchs">
        <div v-for="matchListByDate in getMatchListByDate()" :key="matchListByDate.id">
          <div class="border rounded mb-3 bg-white">
            <button class="btn btn-blue font-weight-bold p-3 w-100 text-left font-title d-flex justify-content-between"
                    v-b-toggle="'match-day-' + matchListByDate[0].startDate">
              {{ dateUtils.formatHumanDate(matchListByDate[0].startDate, true) }}
              <b-iconstack font-scale="1" class="text-white-light m-0 mr-3">
                <b-icon stacked icon="caret-down-fill"></b-icon>
              </b-iconstack>
            </button>
            <b-collapse :id="'match-day-' + matchListByDate[0].startDate" visible
                        class="collapse navbar-collapse pl-3 pr-3 pb-3">
              <div v-for="(match, index) in matchListByDate" :key="match.id">
                <div class="col-12 mt-3 mb-3 bg-blue rounded p-3"
                     v-if="compareMatchsDateTime(matchListByDate[index - 1], matchListByDate[index]) !== 0">
                  <div class="col-md-5 col-12 row d-flex align-items-center">
                    <b-iconstack font-scale="1" class="text-white m-0 mr-3">
                      <b-icon stacked icon="clock"></b-icon>
                    </b-iconstack>
                    <p class="text-white m-0 font-weight-bold h5">
                      {{ dateUtils.formatHumanTime(match.startDate) }}
                    </p>
                  </div>
                </div>
                <MatchCard class="mb-3 ml-3" :match="match"/>
              </div>
            </b-collapse>
          </div>

        </div>
      </div>
      <Loader class="col-12 mt-5 pt-5" v-else/>
    </div>
  </div>
</template>

<script>
import DateUtils from '@/utils/DateUtils';
import AlertBus from '@/event-bus/AlertBus';
import Loader from '@/components/common/Loader';
import MatchCard from '@/components/common/MatchCard';

export default {
  name: 'Sport',
  components: {Loader, MatchCard},
  data() {
    return {
      dateUtils: DateUtils,
      matchs: null,
      pastView: false,
      page: 0,
      size: 50,
    };
  },
  created() {
    this.loadMatchs();
  },
  computed: {
    paginationQueryParam() {
      return `&page=${this.page}&size=${this.size}&sort=startDate,${this.pastView ? 'desc' : 'asc'}`
    }
  },
  methods: {
    loadMatchs() {
      const currentDate = this.dateUtils.formatApiDateTime(new Date());

      let dateQueryParam = `startDate=${currentDate}`
      if (this.pastView) {
        dateQueryParam = `endDate=${currentDate}`
      }
      this.matchs = null;
      this.$http.get(`/api/sport/${this.$route.params.id}/match?${dateQueryParam}&eventIds=${this.$route.params.eventId}${this.paginationQueryParam}`)
          .then((res) => {
            this.matchs = res.data.content;
          })
          .catch((response) => {
            AlertBus.bus.$emit(AlertBus.ERROR, response);
          });
    },
    compareMatchsDateTime(first, second) {
      if (!first || !second) {
        return 1;
      }
      if (first.startDate < second.startDate) {
        console.log(first.startDate)
        console.log(second.startDate)
        return -1;
      }
      if (first.startDate > second.startDate) {
        console.log(first.startDate)
        console.log(second.startDate)
        return 1;
      }
      console.log(first.startDate)
      console.log(second.startDate)
      return 0;
    },
    compareMatchsEvent(first, second) {
      if (this.compareMatchsDateTime(first, second)) {
        return 1;
      }
      if (!first || !second) {
        return 1;
      }
      if (first.event.id < second.event.id) {
        return -1;
      }
      if (first.event.id > second.event.id) {
        return 1;
      }
      return 0;
    },
    compareMatchsDate(first, second) {
      if (!first || !second) {
        return 1;
      }
      const firstStartDate = new Date(first.startDate).setHours(0, 0, 0);
      const secondStartDate = new Date(second.startDate).setHours(0, 0, 0);
      if (firstStartDate < secondStartDate) {
        return -1;
      }
      if (firstStartDate > secondStartDate) {
        return 1;
      }
      return 0;
    },
    getMatchListByDate() {
      if (!this.matchs) {
        return null;
      }
      let matchListByDate = [];
      this.matchs.forEach((match, index) => {
        if (index === 0 || this.compareMatchsDate(this.matchs[index - 1], match) !== 0) {
          matchListByDate.push([]);
        }
        matchListByDate[matchListByDate.length - 1].push(match);
      });

      return matchListByDate;
    },
    clickButton(pastView) {
      if (this.pastView === pastView) {
        return;
      }
      this.pastView = pastView;
      this.page = 0;
      this.loadMatchs()
    },
  },
}
</script>
