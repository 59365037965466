import Vue from 'vue'
import router from '@/router';
import api from '@/vue-axios/axios';
import VueAxios from 'vue-axios';

import i18n from '@/vue-i18n/i18n';

import {BootstrapVue, IconsPlugin} from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.config.productionTip = process.env.NODE_ENV === 'development';

Vue.use(VueAxios, api);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

// https://lokesh-coder.github.io/pretty-checkbox/
import 'pretty-checkbox/src/pretty-checkbox.scss';

import vueHeadful from 'vue-headful';

Vue.component('vue-headful', vueHeadful);

// https://www.npmjs.com/package/vue-js-toggle-button
import { ToggleButton } from 'vue-js-toggle-button'
Vue.component('ToggleButton', ToggleButton)

// Mettre à la fin pour override les autres fichiers css
import '@/assets/app.scss'

new Vue({
    el: '#app',
    router: router,
    i18n: i18n,
    render: h => h('router-view')
});
