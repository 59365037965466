import router from '@/router';

const userToken = 'user-token';

const UserService = {
  setToken(token) {
    localStorage.setItem(userToken, token);
    location.reload();
  },
  getToken() {
    return localStorage.getItem(userToken);
  },
  removeToken() {
    localStorage.removeItem(userToken);
  },
  isLogged() {
    return this.getToken() !== 'undefined' && this.getToken() !== null;
  },
  logOut() {
    this.removeToken();
    router.push("home");
  }
};

export default UserService;
