<template>
    <div>
        <div class="blue-line-absolute d-none d-md-block"></div>
        <div class="row mt-5 mb-4 bg-blue p-4 border rounded">
            <h1 class="col-12 text-center text-white mb-3 font-title">{{ $t('name')}}</h1>
            <h2 class="col-12 text-center text-white font-title">{{ $t('home.title')}}</h2>
            <h2 class="col-12 text-center text-white font-title d-none d-md-block">{{ $t('home.titleSecondLine')}}</h2>
        </div>

        <div class="row">
            <h3 class="col-12 text-center mb-2">{{ $t('home.description')}}</h3>
            <p class="col-12 text-center h5">{{ $t('home.topMatchs')}}</p>
            <div class="col-12" v-if="footballMatchs">
                <div class="mt-4 mb-5 pt-4 pb-4 pl-2 pr-2 bg-white border" v-for="(match) in footballMatchs"
                     :key="match.id">
                    <a :href="'/match/' + match.id" class="col unstyled">
                        <h4 class="col text-center font-title text-blue">{{ match.name }}</h4>
                        <p class="col text-center font-italic font-title">{{
                            dateUtils.formatHumanDateTime(match.startDate) }}</p>
                        <p class="col text-center font-italic font-title">{{ match.event.name }}</p>
                    </a>
                    <BetAdviceCard
                            class="col"
                            :bet="match"
                            :matchName="match.name"
                    />
                    <ChartBet
                            class="col"
                            :data="getFootBallBet(match)"
                            :matchName="match.name"
                            :matchStartDate="match.startDate"
                            :betName="getFootBallBet(match).name"
                    />
                </div>
            </div>
            <Loader class="col mt-5" v-else/>
        </div>
    </div>
</template>

<script>
    import ChartBet from '@/components/common/ChartBet';
    import BetAdviceCard from '@/components/common/BetAdviceCard';
    import AlertBus from '@/event-bus/AlertBus';
    import DateUtils from '@/utils/DateUtils';
    import Loader from '@/components/common/Loader';

    export default {
        name: 'Home',
        components: {ChartBet, Loader, BetAdviceCard},
        data() {
            return {
                footballMatchs: null,
                dateUtils: DateUtils,
            };
        },
        created() {
            this.getFootballMatchs();
        },
        methods: {
            getFootballMatchs() {
                this.$http.get(`/api/category/top-matchs-bet?limit=30`)
                    .then((res) => {
                        this.footballMatchs = res.data;
                    })
                    .catch((response) => {
                        AlertBus.bus.$emit(AlertBus.ERROR, response);
                    });
            },
            getFootBallBet(match) {
                return match.betList.find(bet => bet.code === match.betChoiceVariation.bet.code);
            },
        }
    }
</script>
