<template>
  <div>
      <LoginModal/>
      <Menu/>
      <RouterView class="container body-content"/>
      <Footer/>
      <Alert/>
  </div>
</template>

<script>
    import Menu from '@/components/squelette/Menu';
    import Footer from '@/components/squelette/Footer';
    import Alert from '@/components/squelette/Alert';
    import LoginModal from '@/components/common/LoginModal';

    export default {
        name: 'PageLayout',
        components: { Menu, Footer, Alert, LoginModal },
    };
</script>