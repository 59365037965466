<template>
    <div>
        <h3 class="text-blue text-center text-md-left font-title font-weight-bold h4 mb-4">{{bet.name}}</h3>
        <div v-if="hasAdvice()">
            <p class="text-center text-md-left text-blue h4 mb-3">
                {{$t('match.adviceTitle')}}
                <span class="text-blue font-weight-bold h4">
            {{getAdviceName()}}
            </span>
            </p>
            <p class="border rounded p-3 bg-white text-blue h5 text-justify" v-html="$t('match.adviceMessage', getAdviceDisplay())"></p>
        </div>
        <div v-else>
            <p class="border rounded p-3 bg-white text-blue h5 text-justify">{{$t('match.adviceMissing')}}</p>
        </div>
    </div>
</template>

<script>
    import MatchUtils from "@/utils/MatchUtils";

    export default {
        name: 'BetAdviceCard',
        props: {
            bet: Object,
            matchName: String,
        },
        methods: {
            hasAdvice() {
                return this.bet.betChoiceVariation !== null;
            },
            getDayRange() {
                const startDate = new Date(this.bet.betChoiceVariation.choiceMax.createdDate);
                const endDate = new Date(this.bet.betChoiceVariation.choiceMin.createdDate);
                const diffTime = Math.abs(endDate - startDate);
                return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
            },
            getAdviceDisplay() {
                return {
                    name: this.getAdviceName(),
                    variation: this.getAdviceVariation(),
                    lastOdd: this.getAdviceLastOdd(),
                    dayRange: this.getDayRange(),
                }
            },
            getAdviceName() {
                return this.getRealName(this.bet.betChoiceVariation.choiceMin.name);
            },
            getAdviceVariation() {
                return this.applyBoldOnText((this.bet.betChoiceVariation.variation * 100).toFixed(2) + '%');
            },
            getAdviceLastOdd() {
                return this.applyBoldOnText(this.bet.betChoiceVariation.choiceMin.odd);
            },
            getRealName(betName) {
                return MatchUtils.replaceByTeamName(this.matchName, betName);
            },
            applyBoldOnText(text) {
                return '<span class="font-weight-bold">' + text + '</span>';
            }
        }
    }
</script>
