<template>
    <div>

        <b-modal hide-footer :title="$t('menu.login')" ref="login-modal">

            <FormValidation @submit="singIn">
                <div class="row">
                    <div class="form-group col">
                        <label for="email">
                            {{ $t("login.email") }} (<strong>pbaudoin@odd.com</strong>)
                        </label>
                        <input
                                id="email"
                                v-model="email"
                                required
                                type="email"
                                class="form-control">
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col">
                        <label for="password">
                            {{ $t("login.password") }} (<strong>1234Azerty</strong>)
                        </label>
                        <input
                                id="password"
                                v-model="password"
                                required
                                pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{4,}"
                                type="password"
                                class="form-control">
                        <div class="invalid-feedback">{{ $t("login.error.password") }}</div>
                    </div>
                </div>
                <button type="submit" class="btn btn-blue mx-auto w-50 d-flex justify-content-center font-title">
                    {{ $t("login.button") }}
                </button>
            </FormValidation>

        </b-modal>

    </div>
</template>

<script>
    import FormValidation from '@/components/squelette/FormValidation';
    import AlertBus from '@/event-bus/AlertBus';
    import UserService from '@/services/UserService';

    export default {
        name: 'LoginModal',
        components: {FormValidation},
        data() {
            return {
                email: null,
                password: null,
            };
        },
        created() {
            AlertBus.bus.$on('askLogin', this.openLogin);
        },
        methods: {
            openLogin() {
                this.$refs['login-modal'].show();
            },
            singIn() {
                let loginRequest = {
                    "login": this.email,
                    "password": this.password,
                };
                this.$http.defaults.headers.Authorization = null;
                this.$http.post('/api/auth/login', loginRequest)
                    .then((res) => {
                        UserService.setToken(res.data.token);
                    })
                    .catch(() => {
                        AlertBus.bus.$emit(AlertBus.ERROR, this.$t('login.error.singIn'));
                    });
            }
        }
    }
</script>
