<template>
    <div class="message-container">
        <div v-show="alertClass !== ''" :class="`alert ${alertClass}`" role="alert">
            {{ message }}
        </div>
    </div>
</template>

<script>
    import AlertBus from '@/event-bus/AlertBus';

    export default {
        name: 'Alert',
        data() {
            return {
                message: '',
                alertClass: '',
                delayAfterClose: 5000,
            };
        },
        mounted() {
            AlertBus.bus.$on(AlertBus.SUCCESS, this.displaySuccess);
            AlertBus.bus.$on(AlertBus.ERROR, this.displayError);
        },
        beforeDestroy() {
            AlertBus.bus.$off(AlertBus.SUCCESS);
            AlertBus.bus.$off(AlertBus.ERROR);
        },
        methods: {
            displaySuccess(message) {
                this.alertClass = 'alert-success';
                this.message = message;
                setTimeout(this.hideAlert, this.delayAfterClose);
            },
            displayError(response) {
                if (!response.response) {
                    this.message = response;
                    setTimeout(this.hideAlert, this.delayAfterClose);
                } else {
                    switch (Math.floor(response.response.status / 100)) {
                        // Error 4xx
                        case 4:
                            this.message = response.response.data.message;
                            break;
                        // Error 5xx
                        case 5:
                            this.message = 'Server error, please reload';
                            break;
                        default:
                            this.message = response.response.data;
                            break;
                    }
                }
                this.alertClass = 'alert-danger';
                setTimeout(this.hideAlert, this.delayAfterClose);
            },
            hideAlert() {
                this.alertClass = '';
            },
        },
    };
</script>

<style lang="scss" scoped>
    .message-container {
        position: fixed;
        bottom: 5%;
        left: 50%;
        transform: translate(-50%, -5%);
    }
</style>
