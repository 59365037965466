<template>
    <div>
        <footer class="bg-blue p-5 mt-5">
            <div class="container container-big">
                <div class="row mb-3">
                    <h1 class="col-12 text-center text-white">{{ $t('name')}}</h1>
                </div>
            </div>
        </footer>
    </div>
</template>

<script>
    export default {
        name: 'Footer',
    }
</script>
