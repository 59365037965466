<template>
    <div ref="chart">
    </div>
</template>

<script>
    // https://www.npmjs.com/package/apexcharts
    import ApexCharts from 'apexcharts'
    import DateUtils from '@/utils/DateUtils';
    import MatchUtils from "@/utils/MatchUtils";
    import FRLocale from "apexcharts/dist/locales/fr.json";

    export default {
        name: 'ChartBet',
        props: {
            data: Object,
            matchName: String,
            matchStartDate: String,
            betName: String,
        },
        data() {
            return {
                options: null,
                series: [],
                categories: this.data.choiceDateList,
            };
        },
        methods: {
            loadGraph() {
                this.series = [];
                Object.values(this.data.choiceList).forEach((choiceNameOddList) => {
                    let serie = {
                        name: this.getRealName(choiceNameOddList[0].name),
                        data: [],
                    };
                    choiceNameOddList.forEach((oddData) => {
                        while (this.categories[serie.data.length] !== oddData.createdDate) {
                            serie.data.push(oddData.odd)
                        }
                        serie.data.push(oddData.odd)
                    });
                    this.series.push(serie);
                });
                this.options = {
                    chart: {
                        locales: [FRLocale],
                        defaultLocale: 'fr',
                        type: 'line',
                        height: 400,
                        dropShadow: {
                            enabled: true,
                            color: '#000',
                            top: 18,
                            left: 7,
                            blur: 10,
                            opacity: 0.2,
                        },
                        toolbar: {
                            show: !this.isMobile(),
                        },
                    },
                    series: this.series,
                    xaxis: {
                        type: 'datetime',
                        categories: this.categories.map(choiceDate => DateUtils.toTime(choiceDate)),
                        max: DateUtils.toTime(this.matchStartDate),
                        title: {
                            text: this.$t("chart.time")
                        },
                        labels: {
                            datetimeUTC: false
                        }
                    },
                    yaxis: {
                        title: {
                            text: this.$t("chart.odds")
                        },
                        min: 1
                    },
                    tooltip: {
                        x: {
                            format: 'dd MMMM HH:mm',
                            locale:'fr',
                        }
                    },
                    title: {
                        text: this.betName,
                        align: 'left'
                    },
                    stroke: {
                        curve: 'smooth'
                    },
                    grid: {
                        borderColor: '#e7e7e7',
                        row: {
                            colors: ['#f3f3f3', 'transparent'],
                            opacity: 0.5
                        },
                    },
                    animations: {
                      enabled: false,
                    },
                    markers: {
                        size: 0,
                        style: 'hollow',
                    },
                };
                let chart = new ApexCharts(this.$refs.chart, this.options);
                chart.render();
            },
            getRealName(betName) {
                return MatchUtils.replaceByTeamName(this.matchName, betName);
            },
            isMobile() {
                return screen.width <= 760;
            }
        },
        mounted() {
            this.loadGraph();
        },
    }
</script>
