<template>
  <div>
    <div class="row mt-5">
      <div class="col-12 mb-4 bg-blue p-4 border rounded">
        <h1 class="text-center text-white font-title">{{ $t('simulation.category.title') }}</h1>
      </div>
    </div>
    <div class="row">
      <div class="col-12 p-0">
        <div class="border rounded bg-white">
          <input type="button"
                 :value="$t('simulation.normal.start')"
                 class="btn btn-blue mx-auto w-100 d-flex justify-content-center"
                 v-on:click="simulate()"/>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 mt-md-0 mt-3 p-0">
        <div v-if="data != null">
          <div class="mt-4 mb-5" v-for="(category) in data"
               :key="category.id">
            <ChartSimulationCategory
                class="col"
                :data="category"/>
          </div>
        </div>
        <div class="col-12 mt-5 pt-5" v-else>
          <Loader v-if="loadingData"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ChartSimulationCategory from '@/components/common/ChartSimulationCategory';
import AlertBus from '@/event-bus/AlertBus';
import Loader from '@/components/common/Loader';

export default {
  name: 'SimulationCategory',
  components: {Loader, ChartSimulationCategory},
  data() {
    return {
      data: null,
      loadingData: false,
      limit: 20,
      sportId: undefined,
    };
  },
  methods: {
    simulate() {
      this.loadingData = true;
      this.data = null;

      if (this.$route.query.limit) {
        this.limit = this.$route.query.limit;
      }

      let url = `/api/category?limit=${this.limit}&isValueTest=true`;
      if (this.$route.query.sportId) {
        url += `&sportId=${this.$route.query.sportId}`;
      }
      this.$http.get(url)
          .then((res) => {
            this.data = res.data;
            this.loadingData = false;
          })
          .catch((response) => {
            AlertBus.bus.$emit(AlertBus.ERROR, response);
          });
    },
  },
}
</script>
