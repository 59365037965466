export default {
    name: 'Odds Waver',
    menu: {
        home: 'Home',
        login: 'Login',
        logout: 'Log out',
        graph: 'Graph',
    },
    lang: {
        language: 'Language',
        fr: 'Français',
        en: 'English',
    },
    error: {
        requiresAuth: 'You need to be loged in to access to this page. Redirect to login page ...',
        disconnectServer: 'You\'ve been disconncted from the server, the current page will reload automatically ...',
    },
    home: {
        title: 'Visualize the variations in the odds of your future sports bets',
        titleSecondLine: 'And bet on the best without further ado',
        description: 'Odds Waver will allow you to optimize your sports bets thanks to the evolution of the odds before the start of the matches!',
        topMatchs: 'The top matches of the moment are there:',
    },
    date: {
        humanDate: 'dddd D MMMM',
        humanTime: 'hh:mm a',
    },
    login: {
        email: 'Email',
        password: 'Password',
        button: 'Sign in',
        error: {
            password: 'Must contain at least one number and one uppercase and lowercase letter, and at least 4 or more characters',
            singIn: 'Email or password is not valid',
        },
    },
    sport: {
        other: "Other sports",
        search: "Find your match",
        input: {
            event: 'Tournaments',
            hideEvents: 'Hide tournaments',
        },
        pastMatchs: 'Old matchs',
        futureMatchs: 'Future matchs',
    },
    match: {
        uncheckAll: 'Uncheck all',
        adviceTitle: 'The Odds Waver advice:',
        adviceMissing: 'According to the Odds Waver algorithm, no advice could be found, make the right choice!',
        adviceMessage: 'The {name} odds record a drop of {variation} during the last {dayRange} days. We therefore recommend that you bet on {name} at odds of {lastOdd}!',
        card: {
            "empty": "No bet advise for this match"
        },
    },
    chart: {
        odds: 'Odds',
        time: 'Time'
    },
    simulation: {
        variationIsEquals: 'Fixed variation',
        minGraph: 'Minimal variation',
        maxGraph: 'Maximal variation',
        minOdd: 'Min odd',
        maxOdd: 'Max odd',
        onlyHome: 'Home only',
        onlyAway: 'Away only',
        minBalance: 'Min balance (%)',
        maxBalance: 'Max balance (%)',
        minVotePercentDifference: 'Minimum vote percent diffence',
        minExpectedPercentDifference: 'Minimum expected percent difference',
        maxVariance: 'Max variance',
        gain: {
            title: 'Gain as a function of variation',
            data: 'Gain'
        },
        betCount: {
            title: 'Bet count betted as a function of variation',
            data: 'Bet count'
        },
        percentByMatch: {
            title: 'Percent by existing match as a function of variation',
            data: 'Percent by existing match'
        },
        percentOnBetMatch: {
            title: 'Percent by match as a function of variation',
            data: 'Percent by match'
        },
        avgOddWin: {
            title: 'Winning odd average as a function of variation',
            data: 'Odd average'
        },
        avgOddLoose: {
            title: 'Loose ddd average as a function of variation',
            data: 'Odd average'
        },
        variation: {
            data: 'Variation'
        },
        tennis: {
            title: 'Bet tennis simulation',
        },
        foot: {
            title: 'Bet football simulation',
        },
        basketball: {
            title: 'Bet Basket-ball simulation',
        },
        handball: {
            title: 'Bet Handball simulation',
        },
        hockey: {
            title: 'Bet Hockey simulation',
        },
        baseball: {
            title: 'Bet Baseball simulation',
        },
        rugby: {
            title: 'Bet Rugby simulation',
        },
        snooker: {
            title: 'Bet Snooker simulation',
        },
        volley: {
            title: 'Bet Volley simulation',
        },
        normal: {
            title: 'Normal simulation',
            start: 'Start simulation',
            chart: {
                title : 'Gain as of function of time',
                xaxis: 'Time',
                yaxis: 'Gain (€)',
            }
        },
        category: {
            title: 'Categories simulation',
            similar: 'See similar categories',
            islinear: 'Linear mode',
            details: {
                odd: 'Odd',
                variation: 'Variation',
                balance: 'Balance',
                vote: 'Vote',
                action: {
                    text: 'Action',
                    success: 'Category has been modified'
                },
                betable: 'Betable',
                notBetable: 'Not betable',
                sport: 'Sport',
                gain: 'Gain',
                count: 'Count',
                gainPercent: 'Gain in %'
            }
        },
    },
};