<template>
    <div>
        <nav class="navbar navbar-expand-sm bg-blue navbar-dark font-title">
            <div class="container container-big">
                <router-link class="navbar-brand" to="/">{{ $t("name") }}</router-link>

                <button class="navbar-toggler"
                        type="button"
                        v-b-toggle.menu-content>
                    <span class="navbar-toggler-icon"></span>
                </button>

                <b-collapse id="menu-content" class="collapse navbar-collapse">
                    <ul class="navbar-nav mr-auto ml-4">
                        <li v-for="sport in sports.slice(0,8)" class="nav-item ml-2 mr-2" :key="sport.id">
                            <a class="nav-link" :href="'/sport/' + sport.id">{{ sport.name }}</a>
                        </li>
                        <b-nav-item-dropdown :text="$t('sport.other')" right class="ml-2 mr-2">
                            <div v-for="sport in sports.slice(8)" :key="sport.id">
                                <b-dropdown-item :href="'/sport/' + sport.id">{{ sport.name }}</b-dropdown-item>
                            </div>
                        </b-nav-item-dropdown>
                    </ul>
                    <ul class="navbar-nav">
                        <b-nav-item-dropdown :text="$t('lang.language')" right>
                            <b-dropdown-item v-on:click="setLocale('fr')">{{ $t("lang.fr") }}</b-dropdown-item>
                            <b-dropdown-item v-on:click="setLocale('en')">{{ $t("lang.en") }}</b-dropdown-item>
                        </b-nav-item-dropdown>
<!--                        <li class="nav-item" v-if="!isLogged()">-->
<!--                            <a class="nav-link" id="show-btn" @click="showModal">{{ $t("menu.login") }}</a>-->
<!--                        </li>-->
<!--                        <li v-else class="nav-item">-->
<!--                            <a class="nav-link" v-on:click="logOut()">{{ $t("menu.logout") }}</a>-->
<!--                        </li>-->
                    </ul>
                </b-collapse>
            </div>
        </nav>

    </div>
</template>

<script>
    import UserService from '@/services/UserService';
    import AlertBus from '@/event-bus/AlertBus';

    export default {
        name: 'Menu',
        data() {
            return {
                sports: []
            };
        },
        created() {
            this.$http.get('/api/sport')
                .then((res) => {
                    this.sports = res.data;
                })
                .catch(() => {

                });
        },
        methods: {
            isLogged() {
                return UserService.isLogged();
            },
            logOut() {
                UserService.logOut();
            },
            showModal() {
                AlertBus.bus.$emit('askLogin');
            },
            setLocale(lang) {
                localStorage.setItem('locale', lang);
                this.$i18n.locale = lang;
            },
        }
    }
</script>
