<template>
    <div class="border rounded pt-1 pb-1 pl-2 pr-2">
        <a class="unstyled" :href="'/match/' + match.id">
            <div class="row pt-3 pb-3 pl-4 pr-4">
                <div class="col-12 col-md-5">
                    <div class="row border rounded">
                        <div class="col-10 d-flex flex-column align-items-start justify-content-between mb-md-0 p-0 m-0">
                            <div class="m-0 border-bottom w-100 p-2">
                                <p class="font-title h6 m-0" :class="homeHightlight()">
                                    {{this.getHomeName()}}
                                </p>
                            </div>
                            <div class="m-0 w-100 p-2">
                                <p class="font-title h6 m-0" :class="awayHightlight()">
                                    {{this.getAwayName()}}
                                </p>
                            </div>
                        </div>
                        <div class="col-2 d-flex flex-column align-items-start justify-content-between p-0 m-0">
                            <div class="m-0 border-bottom w-100 p-2 font-title">
                                <p v-if="match.matchResult != null && match.matchResult.homeScore != null" class="text-blue h6 m-0" :class="homeHightlight()">
                                    {{match.matchResult.homeScore}}
                                </p>
                                <p v-else class="text-blue h6 m-0">-</p>
                            </div>
                            <div class="m-0 w-100 p-2 font-title">
                                <p v-if="match.matchResult != null && match.matchResult.awayScore != null" class="text-blue h6 m-0" :class="awayHightlight()">
                                    {{match.matchResult.awayScore}}
                                </p>
                                <p v-else class="text-blue h6 m-0">-</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="match.betChoiceVariation != null" class="col-12 col-md-7 d-flex flex-column align-items-start justify-content-between">
                    <div class="row ml-md-3 mt-md-0 mt-3">
                        <p v-if="match.betChoiceVariation != null" class="h6 m-0 mb-2">
                            <span class="font-italic text-blue ">{{match.betChoiceVariation.bet.name}} :</span>
                            <span class="font-weight-bold ml-1" :class="getClassResult(match)">{{getOddRealName(match.betChoiceVariation.choiceMin.name)}}</span>
                        </p>
                    </div>
                    <div class="row ml-md-3 mt-md-0 mt-3">
                        <p class="text-blue m-1">
                            <span class="odd-card">{{match.betChoiceVariation.choiceMin.odd}}</span>
                            <b-iconstack font-scale="1" class="text-blue ml-3">
                                <b-icon stacked icon="arrow-down"></b-icon>
                            </b-iconstack>
                            <span class="ml-1 font-weight-bold font-title">{{displayVariation(match.betChoiceVariation.variation)}}</span>
                        </p>
                    </div>
                </div>
                <div v-else class="col-12 col-md-7 row d-flex align-items-center">
                    <div class="col-12">
                        {{ $t('match.card.empty')}}
                    </div>
                </div>
            </div>
        </a>
    </div>
</template>

<script>
    import MatchUtils from "@/utils/MatchUtils";

    export default {
        name: 'MatchCard',
        props: {
            match: Object,
        },
        methods: {
            getOddRealName(name) {
                return MatchUtils.replaceByTeamName(this.match.name, name);
            },
            getHomeName() {
                return MatchUtils.getHomeName(this.match.name);
            },
            getAwayName() {
                return MatchUtils.getAwayName(this.match.name);
            },
            homeHightlight() {
                if (this.match.matchResult == null || this.match.matchResult.homeScore  == null) {
                    return 'font-weight-normal';
                }
                if (this.match.matchResult.homeScore >= this.match.matchResult.awayScore) {
                    return 'font-weight-bold';
                }
                return 'font-weight-normal';
            },
            awayHightlight() {
                if (this.match.matchResult == null || this.match.matchResult.awayScore == null) {
                    return 'font-weight-normal';
                }
                if (this.match.matchResult.homeScore <= this.match.matchResult.awayScore) {
                    return 'font-weight-bold';
                }
                return 'font-weight-normal';
            },
            displayVariation(variation) {
                return (variation * 100).toFixed(2) + '%';
            },
            getClassResult(match) {
                if(match.mainBetResult === 'WON') {
                    return "match-won";
                } else if(match.mainBetResult === 'LOST') {
                    return "match-lost";
                } else if(match.mainBetResult === 'CANCEL') {
                    return 'match-cancel';
                }
                return 'match-cancel';
            },
        },
    }
</script>
