<template>
  <div>
    <div ref="chart" class="col-12">
    </div>
    <div class="row">
      <div class="ml-2 mr-4" v-if="this.data.betCategory !== null">
        <a class="btn btn-blue" :href="'/simulation/category/' + this.data.betCategory.id + '/similar'" target="_blank">
          {{ $t('simulation.category.similar') }}
        </a>
      </div>
      <div>
        <label class="m-0">
          <input type="checkbox"
                 v-on:change="updateGraph()"
                 v-model="isLinear"/>
          <span class="ml-2">{{ $t('simulation.category.islinear') }}</span>
        </label>
      </div>
    </div>
  </div>
</template>

<script>
// https://www.npmjs.com/package/apexcharts
import ApexCharts from 'apexcharts'
import DateUtils from "../../utils/DateUtils";

export default {
  name: 'ChartSimulationNormal',
  props: {
    data: Object,
  },
  data() {
    return {
      options: null,
      series: [],
      categories: [],
      isLinear: true,
      chart: null
    };
  },
  methods: {
    updateSeriesAndCategories() {
      this.series = [];
      this.categories = [];
      let serieToDisplay = {
        name: this.$t('simulation.normal.chart.yaxis'),
        data: [],
      };
      let currentGain = 0;
      let chartIndex = 0;
      this.data.oddsList.forEach((odd, index) => {
        if (this.data.winnersList[index] === 'WON') {
          let commissionPercent = 0.9;
          if (odd > 5) {
            commissionPercent = 0.8;
          }
          currentGain += (odd - 1) * commissionPercent;
        } else if (this.data.winnersList[index] === 'LOST') {
          currentGain--;
        }
        if (this.data.winnersList[index] !== undefined) {
          chartIndex++;
          let dataObject = {
            name: this.data.matchNamesList[index],
            odd: this.data.winnersList[index],
            x: this.isLinear ? chartIndex : DateUtils.toTime(this.data.matchDatesList[index]),
            y: currentGain.toFixed(2),
          };
          serieToDisplay.data.push(dataObject);
        }
      });
      this.series.push(serieToDisplay);
    },
    updateOptions() {
      this.options = {
        chart: {
          type: 'line',
          height: 400,
          dropShadow: {
            enabled: true,
            color: '#000',
            top: 18,
            left: 7,
            blur: 10,
            opacity: 0.2,
          },
          toolbar: {
            show: !this.isMobile(),
          },
          zoom: {
            type: 'x',
            enabled: true,
            autoScaleYaxis: true
          },
        },
        series: this.series,
        xaxis: {
          type: this.isLinear ? 'numeric' : 'datetime',
          title: {
            text: this.$t("simulation.normal.chart.xaxis")
          },
          labels: {
            datetimeUTC: false
          }
        },
        yaxis: {
          title: {
            text: this.$t('simulation.normal.chart.yaxis')
          },
        },
        tooltip: {
          custom: function (opts) {
            let name = opts.ctx.w.config.series[opts.seriesIndex].data[opts.dataPointIndex].name;
            let odd = opts.ctx.w.config.series[opts.seriesIndex].data[opts.dataPointIndex].odd;
            let time = DateUtils.formatHumanDateTime(opts.ctx.w.config.series[opts.seriesIndex].data[opts.dataPointIndex].x);
            let gain = opts.ctx.w.config.series[opts.seriesIndex].data[opts.dataPointIndex].y;
            return '<div></div><div class="apexcharts-tooltip-title" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px; display: flex;"><strong>' + name + '</strong> : ' + odd + '</div>' +
                '<div class="apexcharts-tooltip-series-group apexcharts-active" style="display: flex;">' +
                '<span class="apexcharts-tooltip-marker" style="background-color: rgb(0, 143, 251);"></span>' +
                '<div class="apexcharts-tooltip-text" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">' +
                '<div class="apexcharts-tooltip-y-group"><span class="apexcharts-tooltip-text-label">' + gain + '€ : </span>' +
                '<span class="apexcharts-tooltip-text-value">' + time + '</span>' +
                '</div><div class="apexcharts-tooltip-z-group"><span class="apexcharts-tooltip-text-z-label"></span>' +
                '<span class="apexcharts-tooltip-text-z-value"></span></div></div>' +
                '</div></div>'
          }
        },
        title: {
          text: (this.data.betCategory !== null ? this.data.betCategory.id + ' - ' : '') +
              (this.data.sportName !== null ? this.data.sportName : '')+
              (this.data.betName !== null ? ' - ' + this.data.betName + ' : ' + this.data.choiceName : '') +
              (this.data.sportName !== null || this.data.betName !== null ? ' | ' : '') +
              this.data.count + ' paris pour ' + this.data.gain + '€ de gain',
          align: 'left'
        },
        stroke: {
          curve: 'smooth'
        },
        grid: {
          borderColor: '#e7e7e7',
          row: {
            colors: ['#f3f3f3', 'transparent'],
            opacity: 0.5
          },
        },
        animations: {
          enabled: false,
        },
        markers: {
          size: 0,
        },
      };
    },
    updateGraph() {
      this.updateSeriesAndCategories();
      this.updateOptions();
      this.chart.updateOptions({
        series: this.series,
        xaxis: {
          type: this.isLinear ? 'numeric' : 'datetime',
          title: {
            text: this.$t("simulation.normal.chart.xaxis")
          },
          labels: {
            datetimeUTC: false
          }
        },
      })
    },
    loadGraph() {
      this.updateSeriesAndCategories();
      this.updateOptions();
      this.chart = new ApexCharts(this.$refs.chart, this.options);
      this.chart.render();
    },
    isMobile() {
      return screen.width <= 760;
    }
  },
  mounted() {
    this.loadGraph();
  },
}
</script>
