
export default {
  formatMatchName(matchName, format) {
    const matchArray = matchName.split(' - ');
    if (matchArray.length === 2) {
      const homeName = matchArray[0];
      const visitorName = matchArray[1];
      if(format === "%1%") {
        return homeName;
      }
      if(format === "%2%") {
        return visitorName;
      }
    }
    return null;
  },
  getHomeName(matchName) {
    const matchArray = matchName.split(' - ');
    if (matchArray.length === 2) {
      return matchArray[0];
    }
    return null;
  },

  getAwayName(matchName) {
    const matchArray = matchName.split(' - ');
    if (matchArray.length === 2) {
      return matchArray[1];
    }
    return null;
  },
  replaceByTeamName(matchName, stringToReplace) {
    return stringToReplace
        .replace(/%1%/g, this.formatMatchName(matchName, "%1%"))
        .replace(/%2%/g, this.formatMatchName(matchName, "%2%"));
  },
};
