<template>
    <div class="container-full-width">
        <div v-if="match">
            <div class="row mt-5">
                <vue-headful
                        :title="match.name"
                        :description="dateUtils.formatHumanDateTime(match.startDate)"
                />
              <div class="bg-blue p-4 pl-5 pr-5 mx-auto border rounded cursor" v-on:click="sortBetByVariation()">
                <a :href="'https://www.sofascore.com/matchs/' + getIdSofascore()" target="_blank"
                   class="unstyled">
                  <h1 class="text-center font-title text-white">{{ match.name }}</h1>
                  <h1 v-if="match.matchResult !== null" class="text-center font-title text-white">
                    {{ match.matchResult.homeScore }} -
                    {{ match.matchResult.awayScore }}</h1>
                  <h2 class="text-center font-title text-white-light">
                    {{ dateUtils.formatHumanDateTime(match.startDate) }}</h2>
                  <h3 class="text-center font-title text-white-light">{{ match.event.name }}</h3>
                </a>
              </div>
            </div>
            <div class="row">
                <div class="col-10 offset-1">
                    <div class="row mt-5 mb-5 d-flex" v-for="(bet, index) in match.betList" :key="bet.id">
                        <div class="col p-4">
                            <div class="row">
                                <div class="col-12">
                                    <BetAdviceCard
                                            :bet="bet"
                                            :matchName="match.name"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-7 offset-0 offset-md-1 p-4 bg-white border rounded">
                            <div class="row" v-if="seeAll(index)">
                                <div class="col-12">
                                    <ChartBet
                                            :data="bet"
                                            :matchName="match.name"
                                            :matchStartDate="match.startDate"
                                    />
                                </div>
                            </div>
                            <Loader v-else/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Loader class="col-12 mt-5 pt-5" v-else/>
    </div>
</template>

<script>
    import ChartBet from '@/components/common/ChartBet';
    import BetAdviceCard from '@/components/common/BetAdviceCard';
    import DateUtils from '@/utils/DateUtils';
    import AlertBus from '@/event-bus/AlertBus';
    import Loader from '@/components/common/Loader';

    export default {
        name: 'Match',
        components: {ChartBet, Loader, BetAdviceCard},
        data() {
            return {
                event: null,
                dateUtils: DateUtils,
                match: null,
                seeAllBet: true,
                betAdvice: BetAdviceCard,
            };
        },
        created() {
            this.loadMatchs();
        },
        methods: {
            loadMatchs() {
                this.$http.get(`/api/match/${this.$route.params.id}/bet`)
                    .then((res) => {
                        this.match = res.data;
                    })
                    .catch((response) => {
                        AlertBus.bus.$emit(AlertBus.ERROR, response);
                    });
            },
            sortBetByVariation() {
                this.match.betList = this.match.betList.sort(this.compareBetVariation);
            },
            compareBetVariation(first, second) {
                if (!first || !second) {
                    return 1;
                }
                const firstMaxVariation = first.betChoiceVariation.variation;
                const secondMaxVariation = second.betChoiceVariation.variation;
                if (firstMaxVariation < secondMaxVariation) {
                    return 1;
                }
                if (firstMaxVariation > secondMaxVariation) {
                    return -1;
                }
                return 0;
            },
            seeAll(index) {
                return index < 8 || this.seeAllBet;
            },
            scroll() {
                window.onscroll = () => {
                    let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight + 2000 >= document.documentElement.offsetHeight;
                    if (bottomOfWindow) {
                        this.seeAllBet = true
                    }
                };
            },
          getIdSofascore() {
            if (this.match.matchResult !== null) {
              return this.match.matchResult;
            }
            return null;
          },
        },
        mounted() {
            this.scroll();
        }
    }
</script>
