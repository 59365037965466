import Moment from 'moment';
import i18n from '@/vue-i18n/i18n';


export default {
    formatDate(date, format) {
        return Moment(date).locale(i18n.locale).format(format);
    },
    formatHumanDateTime(date) {
        return this.formatDate(date, i18n.t('date.humanDate')) + " " +
            this.formatDate(date, i18n.t('date.humanTime'));
    },
    formatHumanDate(date, capitalizeFirstLetter = false) {
        if (capitalizeFirstLetter) {
            return this.capitalizeFirstLetter(this.formatDate(date, i18n.t('date.humanDate')));
        }
        return this.formatDate(date, i18n.t('date.humanDate'));
    },
    formatHumanTime(date, capitalizeFirstLetter = false) {
        if (capitalizeFirstLetter) {
            return this.capitalizeFirstLetter(this.formatDate(date, i18n.t('date.humanTime')));
        }
        return this.formatDate(date, i18n.t('date.humanTime'));
    },
    formatApiDateTime(date) {
        return (new Date(date)).toISOString();
    },
    toTime(date) {
        return new Date(date).getTime();
    },
    capitalizeFirstLetter(value) {
        var string = value;
        return string[0].toUpperCase() +
            string.slice(1);
    }
};
