<template>
    <div>
        <div class="row mt-5">
            <div class="col-12 mb-4 bg-blue p-4 border rounded">
                <h1 class="text-center text-white font-title">{{ $t('simulation.normal.title')}}</h1>
            </div>
            <div class="col-12 col-md-3">
                <div class="border rounded bg-white">
                    <input type="button"
                           :value="$t('simulation.normal.start')"
                           class="btn btn-blue mx-auto w-100 d-flex justify-content-center"
                           v-on:click="simulate()"/>
                </div>
            </div>
            <div class="col-12 col-md-9 mt-md-0 mt-3">
                <div v-if="data != null">
                    <ChartSimulationNormal
                            class="col"
                            :data="data"/>
                </div>
                <div class="col-12 col-md-9 mt-5 pt-5" v-else>
                    <Loader v-if="loadingData"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import ChartSimulationNormal from '@/components/common/ChartSimulationNormal';
    import AlertBus from '@/event-bus/AlertBus';
    import Loader from '@/components/common/Loader';

    export default {
        name: 'SimulationNormal',
        components: {Loader, ChartSimulationNormal},
        data() {
            return {
                data: null,
                loadingData: false,
            };
        },
        methods: {
            simulate() {
                this.loadingData = true;
                this.data = null;

                let url = `/api/normal/simulation`;
                this.$http.get(url)
                    .then((res) => {
                        this.data = res.data;
                        this.loadingData = false;
                    })
                    .catch((response) => {
                        AlertBus.bus.$emit(AlertBus.ERROR, response);
                    });
            },
        },
    }
</script>
