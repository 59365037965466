export default {
    name: 'Odds Waver',
    menu: {
        home: 'Accueil',
        login: 'Connexion',
        logout: 'Deconnexion',
        graph: 'Graphique',
    },
    lang: {
        language: 'Langue',
        fr: 'Français',
        en: 'English',
    },
    error: {
        requiresAuth: 'Vous devez être connecté pour accèder à cette page. Redirection vers la page de connexion ...',
        disconnectServer: 'Vous avez été déconnectez du serveur, la page va se recharger automatiquement ...',
    },
    home: {
        title: 'Visualiser les variations des côtes de vos futurs paris sportifs',
        titleSecondLine: 'Et parier sur la meilleure sans plus attendre',
        description: 'Odds Waver va vous permettre d\'optimiser vos paris sportifs grâce à l\'évolution des côtes avant le début des matchs !',
        topMatchs: 'Le top des matchs du moment, c\'est par ici :',
    },
    date: {
        humanDate: 'dddd D MMMM',
        humanTime: 'HH:mm',
    },
    login: {
        email: 'Email de connexion',
        password: 'Mot de passe',
        button: 'Se connecter',
        error: {
            password: 'Votre mot de passe doit contenir une lettre majuscule, une minuscule, un chiffre, et au moins 4 caractères.',
            singIn: 'Email ou mot de passe incorrect',
        },
    },
    sport: {
        other: "Autres sports",
        search: "Trouvez votre match",
        input: {
            event: 'Compétitions',
            hideEvents: 'Masquer les compétitions',
        },
        pastMatchs: 'Matchs passés',
        futureMatchs: 'Matchs à venir',
    },
    match: {
        uncheckAll: 'Tout désélectionner',
        adviceTitle: 'Le conseil Odds Waver :',
        adviceMissing: 'D\'après l\'algorithme Odds Waver, aucun conseil n\'a pu être trouvé, faites le bon choix !',
        adviceMessage: 'La cote {name} enregistre une chute de {variation} au cours des {dayRange} derniers jours. Nous vous recommandons donc de parier sur {name} a une cote de {lastOdd} !',
        card: {
            "empty": "Aucune indication sur ce match"
        },
    },
    chart: {
        odds: 'Cote',
        time: 'Temps'
    },
    simulation: {
        variationIsEquals: 'Variation fixe',
        minGraph: 'Variation minimale',
        maxGraph: 'Variation maximale',
        minOdd: 'Cote minimale',
        maxOdd: 'Cote maximale',
        onlyHome: 'Domicile uniquement',
        onlyAway: 'Extérieur uniquement',
        minBalance: 'Equilibrage minimal (%)',
        maxBalance: 'Equilibrage maximal (%)',
        minVotePercentDifference: 'Pourcentage de vote avantageux min (%)',
        minExpectedPercentDifference: 'Pourcentage habituel avantageux min (%)',
        maxVariance: 'Variation max',
        gain: {
            title: 'Gain en fonction de la variation',
            data: 'Gain'
        },
        betCount: {
            title: 'Nombre de paris joué en fonction de la variation',
            data: 'Nombre de paris joué'
        },
        percentByMatch: {
            title: 'Pourcentage de gain par paris possible en fonction de la variation',
            data: 'Pourcentage par match possible'
        },
        percentOnBetMatch: {
            title: 'Pourcentage de gain par paris en fonction de la variation',
            data: 'Pourcentage par match'
        },
        matchBetWin: {
            title: 'Nombre de paris gagnant en fonction de la variation',
            data: 'Nombre de match'
        },
        matchBetLoose: {
            title: 'Nombre de paris perdant en fonction de la variation',
            data: 'Nombre de match'
        },
        avgOddWin: {
            title: 'Moyenne des cotes gagantes en fonction de la variation',
            data: 'Moyenne des cotes'
        },
        avgOddLoose: {
            title: 'Moyenne des cotes perdantes en fonction de la variation',
            data: 'Moyenne des cotes'
        },
        variation: {
            data: 'Variation'
        },
        tennis: {
            title: 'Simulation de paris sur le Tennis',
        },
        foot: {
            title: 'Simulation de paris sur le Football',
        },
        basketball: {
            title: 'Simulation de paris sur le Basket-ball',
        },
        handball: {
            title: 'Simulation de paris sur le Handball',
        },
        hockey: {
            title: 'Simulation de paris sur le Hockey sur glace',
        },
        baseball: {
            title: 'Simulation de paris sur le Baseball',
        },
        rugby: {
            title: 'Simulation de paris sur le Rugby',
        },
        snooker: {
            title: 'Simulation de paris sur le Billard',
        },
        volley: {
            title: 'Simulation de paris sur le Volley-ball',
        },
        normal: {
            title: 'Simulation normale',
            start: 'Lancer la simulation',
            chart: {
                title : 'Simulation au cours du temps',
                xaxis: 'Temps',
                yaxis: 'Gain (€)',
            }
        },
        category: {
            title: 'Simulation des catégories',
            similar: 'Voir les catégories similaires',
            islinear: 'Mode linéaire',
            details: {
                odd: 'Côte',
                variation: 'Variation',
                balance: 'Equilibrage',
                vote: 'Vote',
                action: {
                    text: 'Action',
                    success: 'La catégorie a bien été modifiée'
                },
                betable: 'Pariable',
                notBetable: 'Non Parible',
                sport: 'Sport',
                gain: 'Gain',
                count: 'Nombre',
                gainPercent: 'Gain en %'
            }
        },
    },
};
