import axios from 'axios';
// import UserService from '@/services/UserService';
// import AlertBus from '@/event-bus/AlertBus';

let api = axios.create({
    headers: {
        'Content-Type': 'application/json',
    },
});
// if(UserService.isLogged()) {
//     api = axios.create({
//         headers: {
//             'Content-Type': 'application/json',
//             'Authorization': `Bearer ${UserService.getToken()}`
//         },
//     });
// }

// Add a response interceptor to handle response
api.interceptors.response.use(response => response, (error) => {
    if (error.response.status === 403 || (error.response.status === 401)) {
        // AlertBus.bus.$emit('askLogin');
    }
    return Promise.reject(error);
});

export default api;
