<template>
    <div>
        <div class="row mt-5">
            <div class="col-12 mb-4 bg-blue p-4 border rounded">
                <h1 class="text-center text-white font-title">{{ $t('sport.search')}}</h1>
            </div>
            <div class="col-12 col-md-3">
                <DatePicker
                        class="mb-3 border rounded bg-white p-0"
                        @selected="selectNewDate"
                        v-model="selectedDate"
                        :highlighted="highlightedDate"
                        :disabled-dates="disabledDate"
                        :full-month-name="true"
                        :monday-first="true"
                        :bootstrap-styling="true"
                        :inline="true"
                        :language="fr"/>
                <p class="mb-1 text-blue font-weight-bold">{{ $t('sport.input.event')}}</p>
                <div class="border rounded bg-white">
                    <input type="button"
                           class="btn btn-blue mx-auto mb-3 w-100 d-flex justify-content-center"
                           :value="$t('match.uncheckAll')"
                           v-on:click="unCheckAll"/>
                    <b-button class="btn btn-blue mb-3 mx-auto w-100 d-flex justify-content-center d-sm-none"
                              type="button" v-b-toggle.eventList>
                        {{ $t('sport.input.hideEvents') }}
                    </b-button>
                    <b-collapse v-if="events" id="eventList" visible>
                        <div v-for="event in events" :key="event.id">
                            <div class="form-check mb-3">
                                <div class="pretty p-default p-curve p-smooth"
                                     v-if="event.count > 0">
                                    <label class="m-0">
                                        <input type="checkbox"
                                               v-on:change="loadMatchs()"
                                               v-model="event.checked"/>
                                        <div class="state">
                                            <label>{{event.name}}
                                                <span>({{event.count}})</span>
                                            </label>
                                        </div>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </b-collapse>
                </div>
            </div>
            <div class="col-12 col-md mt-md-0 mt-3" v-if="matchs">
                <div v-for="(matchListByDate) in getMatchListByDate()" :key="matchListByDate.id">
                    <div class="border rounded mb-3 bg-white">
                        <button class="btn btn-blue font-weight-bold p-3 w-100 text-left font-title d-flex justify-content-between"
                                v-b-toggle="'match-day-' + matchListByDate[0].startDate">
                            {{ dateUtils.formatHumanDate(matchListByDate[0].startDate, true) }}
                            <b-iconstack font-scale="1" class="text-white-light m-0 mr-3">
                                <b-icon stacked icon="caret-down-fill"></b-icon>
                            </b-iconstack>
                        </button>
                        <b-collapse :id="'match-day-' + matchListByDate[0].startDate" visible
                                    class="collapse navbar-collapse pl-3 pr-3 pb-3">
                            <div v-for="(match, index) in matchListByDate" :key="match.id">
                                <div class="col-12 mt-3 mb-3 bg-blue rounded p-3"
                                     v-if="compareMatchsDateTime(matchListByDate[index - 1], matchListByDate[index]) !== 0">
                                    <div class="col-md-5 col-12 row d-flex align-items-center">
                                        <b-iconstack font-scale="1" class="text-white m-0 mr-3">
                                            <b-icon stacked icon="clock"></b-icon>
                                        </b-iconstack>
                                        <p class="text-white m-0 font-weight-bold h5">
                                            {{ dateUtils.formatHumanTime(match.startDate) }}
                                        </p>
                                    </div>
                                </div>
                                <div class="row ml-3"
                                     v-if="compareMatchsEvent(matchs[index - 1], matchs[index]) !== 0">
                                    <div class="col-md-5 col-12">
                                        <a class="unstyled" :href="getEventUriFromMatch(match)">
                                          <p class="mb-1 mt-2 font-weight-bold">
                                              {{ match.event.name }}
                                          </p>
                                        </a>
                                    </div>
                                </div>
                                <MatchCard class="mb-3 ml-3" :match="match"/>
                            </div>
                        </b-collapse>
                    </div>

                </div>
            </div>
            <Loader class="col-12 col-md-9 mt-5 pt-5" v-else/>
        </div>
    </div>
</template>

<script>
    import DateUtils from '@/utils/DateUtils';
    import AlertBus from '@/event-bus/AlertBus';
    import Loader from '@/components/common/Loader';
    import MatchCard from '@/components/common/MatchCard';

    // https://www.npmjs.com/package/vuejs-datepicker
    import DatePicker from "vuejs-datepicker";
    import {fr} from 'vuejs-datepicker/dist/locale'

    export default {
        name: 'Sport',
        components: {Loader, MatchCard, DatePicker},
        data() {
            return {
                dateUtils: DateUtils,
                matchs: null,
                events: null,
                eventIds: [],
                selectedDate: new Date(),
                highlightedDate: {
                    dates: [
                        new Date()
                    ]
                },
                disabledDate: {
                    to: new Date(2020, 4, 1)
                },
                fr: fr,
                page: 0,
                size: 1000,
            };
        },
        created() {
            if (this.$route.query.dateSelected) {
                this.selectedDate = this.$route.query.dateSelected;
            }
            if (this.$route.query.eventIds) {
                this.eventIds = this.$route.query.eventIds;
            }
            this.loadEvents();
        },
        computed: {
          paginationQueryParam() {
            return `&page=${this.page}&size=${this.size}&sort=startDate,asc`
          }
        },
        methods: {
            loadMatchs() {
                const startDate = this.getStartDateForQuery();
                const endDate = this.getEndDateForQuery();
                let eventIds = this.events.filter(event => event.checked).map(event => event.id).join(',');
                this.$router.replace({
                    name: "sport",
                    query: {
                        dateSelected: this.dateUtils.formatApiDateTime(this.selectedDate),
                        eventIds: eventIds,
                    }
                });
                const eventIdsUri = `eventIds=${eventIds}`;

                this.matchs = null;
                this.$http.get(`/api/sport/${this.$route.params.id}/match?startDate=${startDate}&endDate=${endDate}&${eventIdsUri}${this.paginationQueryParam}`)
                    .then((res) => {
                        this.matchs = res.data.content;
                    })
                    .catch((response) => {
                        AlertBus.bus.$emit(AlertBus.ERROR, response);
                    });
            },
            loadEvents() {
                this.$http.get(`/api/sport/${this.$route.params.id}/event?startDate=${this.getStartDateForQuery()}&endDate=${this.getEndDateForQuery()}`)
                    .then((res) => {
                        this.events = res.data.map(event => ({
                            ...event,
                            checked: this.needToCheckOnPageLoading(event),
                        })).sort(this.sortEventComparator);
                        this.loadMatchs();
                    })
                    .catch((response) => {
                        AlertBus.bus.$emit(AlertBus.ERROR, response);
                    });
            },
            getStartDateForQuery() {
                let startDate = new Date(this.selectedDate).setHours(0, 0, 0);
                return this.dateUtils.formatApiDateTime(startDate - (60 * 1000));
            },
            getEndDateForQuery() {
                return this.dateUtils.formatApiDateTime(new Date(this.selectedDate).setHours(23, 59, 59));
            },
            needToCheckOnPageLoading(event) {
                if (this.eventIds.length > 0) {
                    return this.eventIds.includes(event.id);
                } else {
                    return event.canBet;
                }
            },
            sortEventComparator(event1, event2) {
                if (event1.canBet) {
                    return -1;
                }
                if (event2.canBet) {
                    return 1;
                }
                return 0;
            },
            unCheckAll() {
                this.events = this.events.map(event => ({...event, checked: false}));
                this.loadMatchs();
            },
            selectNewDate(newDateSelected) {
                this.selectedDate = newDateSelected;
                this.eventIds = [];
                this.events = null;
                this.loadEvents();
            },
            compareMatchsDateTime(first, second) {
                if (!first || !second) {
                    return 1;
                }
                if (first.startDate < second.startDate) {
                    return -1;
                }
                if (first.startDate > second.startDate) {
                    return 1;
                }
                return 0;
            },
            compareMatchsEvent(first, second) {
                if(this.compareMatchsDateTime(first, second)) {
                    return 1;
                }
                if (!first || !second) {
                    return 1;
                }
                if (first.event.id < second.event.id) {
                    return -1;
                }
                if (first.event.id > second.event.id) {
                    return 1;
                }
                return 0;
            },
            compareMatchsDate(first, second) {
                if (!first || !second) {
                    return 1;
                }
                const firstStartDate = new Date(first.startDate).setHours(0, 0, 0);
                const secondStartDate = new Date(second.startDate).setHours(0, 0, 0);
                if (firstStartDate < secondStartDate) {
                    return -1;
                }
                if (firstStartDate > secondStartDate) {
                    return 1;
                }
                return 0;
            },
            getMatchListByDate() {
                if (!this.matchs) {
                    return null;
                }
                let matchListByDate = [];
                this.matchs.forEach((match, index) => {
                    if (index === 0 || this.compareMatchsDate(this.matchs[index - 1], match) !== 0) {
                        matchListByDate.push([]);
                    }
                    matchListByDate[matchListByDate.length - 1].push(match);
                });

                return matchListByDate;
            },
          getEventUriFromMatch(match) {
              return `/sport/${this.$route.params.id}/event/${match.event.id}`;
          }
        },
    }
</script>
