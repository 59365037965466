<template>
  <div>
    <div class="row mt-5">
      <div class="col-12 mb-4 bg-blue p-4 border rounded">
        <h1 class="text-center text-white font-title">{{ $t('simulation.category.title') }}</h1>
      </div>
      <div class="col-md-3 col-xs-12">
        <h2>Similarités</h2>
        <div class="border rounded bg-white">
          <div class="form-check mb-3 mt-3">
            <div class="pretty p-default p-curve p-smooth">
              <label class="m-0">
                <input type="checkbox"
                       v-on:change="simulate()"
                       v-model="params.odd"/>
                <div class="state">
                  <label>
                    {{ $t('simulation.category.details.odd') }}
                  </label>
                </div>
              </label>
            </div>
          </div>
          <div class="form-check mb-3">
            <div class="pretty p-default p-curve p-smooth">
              <label class="m-0">
                <input type="checkbox"
                       v-on:change="simulate()"
                       v-model="params.variation"/>
                <div class="state">
                  <label>
                    {{ $t('simulation.category.details.variation') }}
                  </label>
                </div>
              </label>
            </div>
          </div>
          <div class="form-check mb-3">
            <div class="pretty p-default p-curve p-smooth">
              <label class="m-0">
                <input type="checkbox"
                       v-on:change="simulate()"
                       v-model="params.balance"/>
                <div class="state">
                  <label>
                    {{ $t('simulation.category.details.balance') }}
                  </label>
                </div>
              </label>
            </div>
          </div>
          <div class="form-check mb-3">
            <div class="pretty p-default p-curve p-smooth">
              <label class="m-0">
                <input type="checkbox"
                       v-on:change="simulate()"
                       v-model="params.nbVote"/>
                <div class="state">
                  <label>
                    {{ $t('simulation.category.details.vote') }}
                  </label>
                </div>
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-9 col-xs-12 mt-md-0 mt-3 p-0">
        <div class="row" v-if="dataTest != null">
          <div class="col-12 mt-4 mb-5">
            <ChartSimulationCategory
                class="col"
                :data="dataTest"/>
          </div>
        </div>
        <div class="col-12 col-md-9 mt-5 pt-5" v-else>
          <Loader v-if="loadingTestData"/>
        </div>
        <div class="row" v-if="data != null">
          <div class="col-12 mt-4 mb-5">
            <ChartSimulationCategory
                    class="col"
                    :data="data"/>
          </div>
        </div>
        <div class="col-12 col-md-9 mt-5 pt-5" v-else>
          <Loader v-if="loadingData"/>
        </div>
      </div>
      <div class="col-md-9 col-xs-12 mt-md-0 mt-3 p-0">
      </div>
      <div class="col-12 mt-md-0 mt-3">
        <div v-if="categories != null && categories.length > 0" class="table-responsive">
          <table class="table table-striped">
            <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Id</th>
              <th scope="col">{{ $t('simulation.category.details.odd') }}</th>
              <th scope="col">{{ $t('simulation.category.details.variation') }}</th>
              <th scope="col">{{ $t('simulation.category.details.balance') }}</th>
              <th scope="col">{{ $t('simulation.category.details.vote') }}</th>
              <th scope="col">{{ $t('simulation.category.details.action.text') }}</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(category, index) in categories" :key="category.id">
              <th scope="row">
                <a :href="'/simulation/category/' + category.id + '/similar'" target="_blank">
                  {{ index + 1 }}
                </a>
              </th>
              <td>{{ category.id }}</td>
              <td>{{ displayValue(category.minOdd, category.maxOdd) }}</td>
              <td>{{ displayValue(category.minVariation * 100, category.maxVariation * 100) }} (en %)</td>
              <td>{{ displayValue(category.minBalance, category.maxBalance, true) }} (en %)</td>
              <td>{{ displayValue(category.minNbVote, category.maxNbVote) }}</td>
              <td>
                <ToggleButton v-model="category.betable"
                              @change="toggleCategory(category.id, category.betable)"
                              :width="80"
                              :sync="true"
                              :labels="{checked: $t('simulation.category.details.betable'),
                              unchecked: $t('simulation.category.details.notBetable')}">
                </ToggleButton>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="col-12 mt-5 pt-5" v-else>
          <Loader v-if="loadingCategories"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ChartSimulationCategory from '@/components/common/ChartSimulationCategory';
import AlertBus from '@/event-bus/AlertBus';
import Loader from '@/components/common/Loader';

export default {
  name: 'SimulationCategory',
  components: {Loader, ChartSimulationCategory},
  data() {
    return {
      dataTest: null,
      data: null,
      categories: null,
      loadingTestData: false,
      loadingData: false,
      loadingCategories: false,
      params: {
        odd: true,
        variation: true,
        balance: true,
        nbVote: true
      }
    };
  },
  created() {
    this.simulate();
  },
  methods: {
    toggleCategory(categoryId, betable) {
      let url = `/api/category/${categoryId}/betable/${betable}`;
      this.$http.patch(url)
          .then((res) => {
            if (res.status === 200) {
              AlertBus.bus.$emit(AlertBus.SUCCESS, this.$t('simulation.category.details.action.success'));
            } else {
              AlertBus.bus.$emit(AlertBus.SUCCESS, res);
            }
          })
          .catch((response) => {
            AlertBus.bus.$emit(AlertBus.ERROR, response);
          });
    },
    displayValue(minValue, maxValue, isBalance = false) {
      let stringToDisplay = "";
      if (minValue == null) {
        stringToDisplay += "- de ";
      } else {
        if (isBalance) {
          minValue = (minValue - 1) * 100;
        }
        stringToDisplay += minValue.toFixed(2).replace(/[.,]00$/, "");
      }
      if(minValue != null && maxValue != null) {
        stringToDisplay += " - ";
      }
      if (maxValue == null) {
        stringToDisplay += " et +";
      } else {
        if (isBalance) {
          maxValue = (maxValue - 1) * 100;
        }
        stringToDisplay += maxValue.toFixed(2).replace(/[.,]00$/, "");
      }
      return stringToDisplay;
    },
    simulate() {
      this.loadingTestData = true;
      this.loadingData = true;
      this.loadingCategories = true;
      this.dataTest = null;
      this.data = null;
      this.categories = null;

      const params = `oddSimilar=${this.params.odd}&variationSimilar=${this.params.variation}&balanceSimilar=${this.params.balance}&nbVoteSimilar=${this.params.nbVote}`;

      let urlTest = `/api/category/${this.$route.params.id}/similar/data?${params}`;
      this.$http.get(urlTest)
          .then((res) => {
            this.dataTest = res.data;
            this.loadingTestData = false;
          })
          .catch((response) => {
            AlertBus.bus.$emit(AlertBus.ERROR, response);
          });

      let url = `/api/category/${this.$route.params.id}/similar/data?${params}&isTestValue=false`;
      this.$http.get(url)
              .then((res) => {
                this.data = res.data;
                this.loadingData = false;
              })
              .catch((response) => {
                AlertBus.bus.$emit(AlertBus.ERROR, response);
              });

      let urlCategories = `/api/category/${this.$route.params.id}/similar?${params}`;
      this.$http.get(urlCategories)
          .then((res) => {
            this.categories = res.data;
            this.loadingCategories = false;
          })
          .catch((response) => {
            AlertBus.bus.$emit(AlertBus.ERROR, response);
          });
    },
  },
}
</script>
