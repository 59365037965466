<template>
    <div>
        <div class="row mt-5">
            <div class="col-12 mb-4 bg-blue p-4 border rounded">
                <h1 class="text-center text-white font-title">{{ $t('simulation.' + sportName + '.title')}}</h1>
            </div>
            <div class="col-12 col-md-3">
                <div class="border rounded bg-white">
                    <div class="form-check p-3">
                        <label class="m-0">
                            <div class="state">
                                <label>{{ $t('simulation.minGraph')}}</label>
                            </div>
                            <input type="number"
                                   v-model="minGraph"/>
                        </label>
                    </div>
                    <div class="form-check p-3">
                        <label class="m-0">
                            <div class="state">
                                <label>{{ $t('simulation.maxGraph')}}</label>
                            </div>
                            <input type="number"
                                   v-model="maxGraph"/>
                        </label>
                    </div>
                    <div class="form-check p-3">
                        <label class="m-0">
                            <div class="state">
                                <label>{{ $t('simulation.minOdd')}}</label>
                            </div>
                            <input type="number"
                                   v-model="minOdd"/>
                        </label>
                    </div>
                    <div class="form-check p-3">
                        <label class="m-0">
                            <div class="state">
                                <label>{{ $t('simulation.maxOdd')}}</label>
                            </div>
                            <input type="number"
                                   v-model="maxOdd"/>
                        </label>
                    </div>
                    <div class="form-check p-3">
                        <div class="pretty p-default p-curve p-smooth">
                            <label class="m-0">
                                <input type="checkbox"
                                       v-model="variationIsEquals"/>
                                <div class="state">
                                    <label>{{ $t('simulation.variationIsEquals')}}</label>
                                </div>
                            </label>
                        </div>
                    </div>
                    <div class="form-check p-3">
                        <div class="pretty p-default p-curve p-smooth">
                            <label class="m-0">
                                <input type="checkbox"
                                       v-model="onlyHome"/>
                                <div class="state">
                                    <label>{{ $t('simulation.onlyHome')}}</label>
                                </div>
                            </label>
                        </div>
                    </div>
                    <div class="form-check p-3">
                        <div class="pretty p-default p-curve p-smooth">
                            <label class="m-0">
                                <input type="checkbox"
                                       v-model="onlyAway"/>
                                <div class="state">
                                    <label>{{ $t('simulation.onlyAway')}}</label>
                                </div>
                            </label>
                        </div>
                    </div>
                    <div class="form-check p-3">
                        <label class="m-0">
                            <div class="state">
                                <label>{{ $t('simulation.minBalance')}}</label>
                            </div>
                            <input type="number"
                                   v-model="minBalance"/>
                        </label>
                    </div>
                    <div class="form-check p-3">
                        <label class="m-0">
                            <div class="state">
                                <label>{{ $t('simulation.maxBalance')}}</label>
                            </div>
                            <input type="number"
                                   v-model="maxBalance"/>
                        </label>
                    </div>
                    <div class="form-check p-3">
                        <label class="m-0">
                            <div class="state">
                                <label>{{ $t('simulation.minVotePercentDifference')}}</label>
                            </div>
                            <input type="number"
                                   v-model="minVotePercentDifference"/>
                        </label>
                    </div>
                    <div class="form-check p-3">
                        <label class="m-0">
                            <div class="state">
                                <label>{{ $t('simulation.minExpectedPercentDifference')}}</label>
                            </div>
                            <input type="number"
                                   v-model="minExpectedPercentDifference"/>
                        </label>
                    </div>
                    <div class="form-check p-3">
                        <label class="m-0">
                            <div class="state">
                                <label>{{ $t('simulation.maxVariance')}}</label>
                            </div>
                            <input type="number"
                                   v-model="maxVariance"/>
                        </label>
                    </div>
                    <hr/>
                    <div v-if="betList">
                        <div v-for="bet in betList" :key="bet.id" class="form-check p-3">
                            <div class="pretty p-default p-curve p-smooth">
                                <label class="m-0">
                                    <input type="checkbox"
                                           v-on:change="simulate(bet)"
                                           v-model="bet.checked"/>
                                    <div class="state">
                                        <label>{{ bet.name }}</label>
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    <Loader class="col-12 p-5" v-else/>
                </div>
            </div>
            <div class="col-12 col-md-9 mt-md-0 mt-3">
                <h2>{{ betChoice }}</h2>
                <div v-if="data">
                    <div v-for="dataType in Object.keys(data[0])" :key="dataType.id">
                        <div v-if="dataType != null && validDataType(dataType)">
                            <ChartSimulation
                                    class="col"
                                    :data="data"
                                    :dataType="dataType"/>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-9 mt-5 pt-5" v-else>
                    <Loader v-if="loadingData"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import ChartSimulation from '@/components/common/ChartSimulation';
    import AlertBus from '@/event-bus/AlertBus';
    import Loader from '@/components/common/Loader';

    export default {
        name: 'Simulation',
        components: {Loader, ChartSimulation},
        data() {
            return {
                data: null,
                variationIsEquals: false,
                minGraph: 1,
                maxGraph: 30,
                minOdd: 1.35,
                maxOdd: 10,
                onlyHome: false,
                onlyAway: false,
                minBalance: 0,
                maxBalance: 100,
                minVotePercentDifference: 0,
                minExpectedPercentDifference: 0,
                maxVariance: 0,
                sportName: 'tennis',
                sportId: 2,
                betList: null,
                loadingData: false,
                betChoice: null,
            };
        },
        created() {
            this.sportName = this.$route.params.name;
            this.sportId = this.$route.params.id;
            this.getBetList()
        },
        methods: {
            getBetList() {
                this.$http.get(`/api/sport/${this.sportId}/bet`)
                    .then((res) => {
                        this.betList = res.data.map(bet => ({
                            ...bet,
                            checked: false,
                        }));
                    })
                    .catch((response) => {
                        AlertBus.bus.$emit(AlertBus.ERROR, response);
                    });
            },
            simulate(betChoice) {
                this.betChoice = betChoice.name;
                this.loadingData = true;
                this.data = null;

                let url = `/api/simulation/${this.sportId}?minVariation=${this.minGraph}&maxVariation=${this.maxGraph}&variationIsEquals=${this.variationIsEquals}`;
                url+= `&onlyHome=${this.onlyHome}&onlyAway=${this.onlyAway}`;
                url+= `&betType=${betChoice.code}&minOdd=${this.minOdd}&maxOdd=${this.maxOdd}`;
                url+= `&minBalance=${this.minBalance}&maxBalance=${this.maxBalance}`;
                if(this.minVotePercentDifference != 0) {
                    url+= `&minVotePercentDifference=${this.minVotePercentDifference}`;
                }
                if(this.minExpectedPercentDifference != 0) {
                    url+= `&minExpectedPercentDifference=${this.minExpectedPercentDifference}`;
                }
                url+= `&maxVariance=${this.maxVariance}`;
                this.$http.get(url)
                    .then((res) => {
                        if (res.data.length > 0) {
                            this.data = res.data;
                        }
                        this.loadingData = false;
                    })
                    .catch((response) => {
                        AlertBus.bus.$emit(AlertBus.ERROR, response);
                    });
            },
            validDataType(dataType) {
                return ['gain', 'betCount', 'percentByMatch', 'percentOnBetMatch', 'matchBetLoose', 'matchBetWin', 'avgOddWin', 'avgOddLoose'].includes(dataType)
            },
        },
    }
</script>
