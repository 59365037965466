<template>
    <div ref="chart">
    </div>
</template>

<script>
    // https://www.npmjs.com/package/apexcharts
    import ApexCharts from 'apexcharts'

    export default {
        name: 'ChartSimulation',
        props: {
            data: Array,
            dataType: String,
        },
        data() {
            return {
                options: null,
                series: [],
                categories: [],
            };
        },
        methods: {
            loadGraph() {
                this.data.forEach((simulationResult) => {
                    this.categories.push(simulationResult.minVariation)
                });

                this.series = [];
                let serieToDisplay = {
                    name: this.$t('simulation.' + this.dataType + '.data'),
                    data: [],
                };
                this.data.forEach((simulationResult) => {
                    serieToDisplay.data.push(simulationResult[this.dataType]);
                });
                this.series.push(serieToDisplay);

                this.options = {
                    chart: {
                        type: 'line',
                        height: 400,
                        dropShadow: {
                            enabled: true,
                            color: '#000',
                            top: 18,
                            left: 7,
                            blur: 10,
                            opacity: 0.2,
                        },
                        toolbar: {
                            show: !this.isMobile(),
                        },
                    },
                    series: this.series,
                    xaxis: {
                        categories: this.categories,
                        title: {
                            text: this.$t('simulation.variation.data')
                        },
                    },
                    yaxis: {
                        title: {
                            text: this.$t('simulation.' + this.dataType + '.data')
                        },
                    },
                    title: {
                        text: this.$t('simulation.' + this.dataType + '.title'),
                        align: 'left'
                    },
                    stroke: {
                        curve: 'smooth'
                    },
                    grid: {
                        borderColor: '#e7e7e7',
                        row: {
                            colors: ['#f3f3f3', 'transparent'],
                            opacity: 0.5
                        },
                    },
                    animations: {
                        enabled: false,
                    },
                    markers: {
                        size: 0,
                    },
                };
                let chart = new ApexCharts(this.$refs.chart, this.options);
                chart.render();
            },
            isMobile() {
                return screen.width <= 760;
            }
        },
        mounted() {
            this.loadGraph();
        },
    }
</script>
